<!-- =========================================================================================
    File Name: Register.vue
    Description: Register Page
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>

  <div
    class="h-screen flex w-full bg-img vx-row no-gutter items-center justify-center "
    :style="{'background-image':`url(${require('@/assets/images/pages/LoginBG.png')})`,'backgroundRepeat': 'no-repeat'}"
  >
    <div
      class="vx-col sm:w-3/5 md:w-3/5 lg:w-3/4 xl:w-3/5 mx-auto self-center mycard"
    >
      <vx-card>
         <div
            class=" absolute flex inset-x-0 top-0 mt-6 justify-center "

            slot="no-body"
          >
            <img
              src="@/assets/images/logo.png"
              style="max-width:100px ;"
              alt=""
            />
          </div>
       <div
          slot="no-body"
          class="full-page-bg-color"
          style="background: transparent radial-gradient(closest-side at 50% 50%, #2f5f80 0%, #003267 100%) 0% 0% no-repeat padding-box;
                box-shadow: -3px 0px 22px #00000029;
                         border-radius:31px 31px 31px 31px;
             opacity: 1;"
        >
          <div class="vx-row">
            <div
              class="vx-col hidden sm:hidden md:hidden lg:block lg:w-1/2 mx-auto self-center"
            >
              <p
                style="text-align: center;color: #FFFFFF;
                 font: normal normal 100 30px/51px Futura PT;
                  letter-spacing: 0px;

                     opacity: 1;"
              >
              {{$t("CallUSTextUS")}}

              </p>

              <p class="vx-row " style="text-align: center;color: #FFFFFF;margin-left:20%;margin-right:20%;
                 font: normal normal 100 30px/51px Futura PT;
                  letter-spacing: 0px; opacity: 1;" >
                     <div class="vx-row " style="text-align: center;color: #FFFFFF;margin-left:20%;margin-right:20%; ">
                      <img src="@/assets/images/WhatsApp.png"   class="ml-1 mr-1 socialImage vx-col"/>
                    <span class="m-4 vx-col" v-if="!$vs.rtl" style="">
                      +201222246224
                     </span>
                    <span class="m-4" v-if="$vs.rtl" style="font: normal;">
                     201222246224+
                    </span>
                     </div>
                     <br/>
                   <div  class="vx-row " style="text-align: center;color: #FFFFFF;margin-left:20%;margin-right:20%; ">
                    <img src="@/assets/images/PhoneIcon.png"   class="ml-1 mr-1 socialImage vx-col"/>
               <span class="m-4 vx-col" v-if="!$vs.rtl">
                   +971 4 517 7676
                </span>
                <span class="m-4 vx-col" v-if="$vs.rtl">
                  971 4 517 7676+
                </span>
                   </div>


                </p>
               <p style="text-align: center;color: #FFFFFF; font-weight:bold;
                 font: normal normal 100 15px/21px Futura PT;
                  letter-spacing: 0px;
                    margin-top:40%;
                     opacity: 1;">Cure Journey Portal</p>
                     <p style="text-align: center;color: #FFFFFF;
                 font: normal normal 100 15px/21px Futura PT;
                  letter-spacing: 0px;
                     opacity: 1;">Add: Souk Al Bahr, Saha offices C, office 202, Dubai, UAE</p>
            </div>
            <div
               class="vx-col sm:w-full md:w-full lg:w-1/2 mx-auto self-center  d-theme-dark-bg"
              style="border-radius: 31px 31px 31px 31px;"
            >
              <div class="px-8 pt-8 register-tabs-container "  >


                <vs-tabs>

                    <ContactUsJWT></ContactUsJWT>

                </vs-tabs>
              </div>
            </div>
          </div>
        </div>
      </vx-card>
    </div>
  </div>
</template>

<script>
import ContactUsJWT from "./ContactUsJWT.vue";
//import RegisterFirebase from "./RegisterFirebase.vue"
//import RegisterAuth0 from "./RegisterAuth0.vue"

export default {
  components: {
    ContactUsJWT
    //RegisterFirebase,
    //RegisterAuth0

  },
   computed: {

  },
};
</script>

<style lang="scss">
 .mycard .vx-card {
  background: unset;
   }
.register-tabs-container {
  min-height: 517px;

  .con-tab {
    padding-bottom: 23px;
  }

}
</style>
