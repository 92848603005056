<!-- =========================================================================================
File Name: RegisterJWT.vue
Description: Register Page for JWT
----------------------------------------------------------------------------------------
Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div class="clearfix mb-5" style="margin-top: 100px;">
    <p style="text-align: center;margin-left:35%;margin-right:35%;" class="vx-row">
      <img src="@/assets/images/Email.png"   class="ml-1 mr-1 socialImage"/>
      <span class="mt-4 ml-2  vx-row" style="font-size:20px;text-align: center;">{{$t('Emailus')}}</span>
      </p>

    <vs-input
      data-vv-validate-on="blur"
      label-placeholder="Name"
      name="UserName"
      :placeholder="$t('Name')"
      v-model="model.UserName"
      class="w-full"
    />
    <span class="text-danger text-sm">{{ errors.first("UserName") }}</span>

    <vs-input
      v-validate="'required|email'"
      data-vv-validate-on="blur"
      name="email"
      type="email"
      label-placeholder="Email"
      :placeholder="$t('Email')"
      v-model="model.Email"
      class="w-full mt-6"
    />

    <span class="text-danger text-sm">{{ errors.first("email") }}</span>
 <vs-input
      label-placeholder="Phone"
      :placeholder="$t('PhoneNumber')"
      v-model="model.PhoneNumber"
      class="w-full mt-6"
    />
     <vs-input
      label-placeholder="Subject"
      :placeholder="$t('Subject')"
      v-model="model.Subject"
      class="w-full mt-6"
    />
      <vs-textarea

      :placeholder="$t('Message')"
      v-model="model.Message"
      class="w-full mt-6"
    />
    <vs-button type="border" @click="back()" class="mt-6">
      {{$t("back")}}
      </vs-button>
    <vs-button
      class="float-right mt-6"
      @click="sendEmail"
      :disabled="!validateForm"
      >{{ $t("Send") }}</vs-button>

  </div>
</template>

<script>
export default {
  data() {
    return {
     model:{}
    };
  },
  components:{
  },
  computed: {
    validateForm() {
     return !this.errors.any() &&  this.model.Email && this.model.Subject &&this.model.Message
    },
    // countries() {
    //   return this.$store.state.CountryList.countries;
    // }
  },
  methods: {
    back(){
       this.$router.go(-1);
    },
     sendEmail(){
        this.$store
        .dispatch("auth/SendMailForContactUs", this.model).then(()=>{
          window.showSuccess();
         this.back();
        })

     }
  }
};
</script>
<style>
.material-icons
{
  font-family: 'Material Icons'!important;
}
.material-icons  :lang(en)
{
  font-family:unset
}
.is_valid {
  color: rgba(136, 152, 170, 0.8);
}
.is_valid:before {
  width: 50%;
}
li {
  /* margin-bottom: 8px; */
  color: #525f7f;
  position: relative;
}

li:before {
  content: "";
  width: 0%;
  height: 2px;
  background: #2ecc71;
  position: absolute;
  left: 0;
  top: 50%;
  display: block;
  transition: all 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}
</style>
<style lang="scss" scoped>
.add-new-data-sidebar {
  ::v-deep .vs-sidebar--background {
    z-index: 52010;
  }

  ::v-deep .vs-sidebar {
    z-index: 52010;
    width: 400px;
    max-width: 90vw;

    .img-upload {
      margin-top: 2rem;

      .con-img-upload {
        padding: 0;
      }

      .con-input-upload {
        width: 100%;
        margin: 0;
      }
    }
  }
}

.scroll-area--data-list-add-new {
  height: calc(100% - 4.3rem);
}
</style>
